.home_news_tag_circles {
    width: 100%;
    position: relative;
    padding-bottom:30vh;
    -webkit-overflow-scrolling: touch;
    max-height: calc(100vh - 65px);
}


.post_list {
    overflow: hidden;
    width: 100%;
    position: relative;
    padding-bottom:30vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    max-height: calc(100vh - 65px);
  }

  .home_news_tag_circles h1 {    
    font-size: 16px;
    line-height: 18px;  
    font-weight: normal;
  }
  
  .home_news_tag_circles section {
    margin-top: 0.1em;
    margin-bottom: 0.005em;
    background-color: #fff;
  }
  